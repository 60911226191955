'use es6';

import Sanitize from 'sanitize'; // https://github.com/gbirke/Sanitize.js

const sanitizer = new Sanitize({
  attributes: {
    a: ['href', 'title', 'target'],
    blockquote: ['cite'],
    ol: ['start', 'type'],
    q: ['cite'],
    ul: ['type']
  },
  elements: ['a', 'b', 'blockquote', 'br', 'caption', 'cite', 'code', 'dd', 'dl', 'dt', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'li', 'ol', 'p', 'pre', 'q', 'small', 'strike', 'strong', 'sub', 'sup', 'u', 'ul', 'font', 'div', 'span'],
  protocols: {
    a: {
      href: ['ftp', 'http', 'https', 'mailto', Sanitize.ALL]
    },
    blockquote: {
      cite: ['http', 'https', Sanitize.RELATIVE]
    },
    img: {
      src: ['http', 'https', Sanitize.RELATIVE]
    },
    q: {
      cite: ['http', 'https', Sanitize.RELATIVE]
    }
  },
  remove_contents: ['style', 'script']
});
export const sanitizeHtmlText = text => {
  const div = document.createElement('div');
  try {
    const element = document.createRange().createContextualFragment(text);
    const fragment = sanitizer.clean_node(element);
    div.appendChild(fragment.cloneNode(true));
  } catch (e) {
    // (╯°□°）╯︵ ┻━┻
  }
  return div.innerHTML;
};
export const makeSafeInnerHTML = string => {
  return {
    __html: sanitizeHtmlText(string || '')
  };
};
export const decodeHtmlEntities = string => {
  const textareaElement = document.createElement('textarea');
  textareaElement.innerHTML = string;
  return textareaElement.value;
};
export const encodeHtmlEntities = string => string && string.replace(/[\u00A0-\u9999<>&]/gim, i => `&#${i.charCodeAt(0)};`);