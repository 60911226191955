export const ErrorCategories = {
  ALREADY_SUBMITTED: 'ALREADY_SUBMITTED',
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  TOO_LATE: 'TOO_LATE',
  UNKNOWN: 'UNKNOWN',
  UNSUBMITTABLE: 'UNSUBMITTABLE'
};
export const ErrorIllustrations = {
  [ErrorCategories.ALREADY_SUBMITTED]: 'successfully-submitted-question',
  [ErrorCategories.RATE_LIMIT_EXCEEDED]: 'errors/general',
  [ErrorCategories.TOO_LATE]: 'errors/hourglass',
  [ErrorCategories.UNKNOWN]: 'errors/general',
  [ErrorCategories.UNSUBMITTABLE]: 'errors/general'
};

// Matching backend https://git.hubteam.com/HubSpot/Feedback/blob/6f3aab250889f1e230e433463cdb7c151eb0fae7/FeedbackCore/src/main/java/com/hubspot/feedback/core/enums/status/PreSubmissionVerifyStatus.java
export const ErrorTypes = {
  ASSOCIATED_TICKET_NOT_FOUND: 'ASSOCIATED_TICKET_NOT_FOUND',
  CONTACT_HAS_RECENTLY_SUBMITTED: 'CONTACT_HAS_RECENTLY_SUBMITTED',
  CONTACT_NOT_IN_LIST: 'CONTACT_NOT_IN_LIST',
  CONTACT_NOT_IN_PORTAL: 'CONTACT_NOT_IN_PORTAL',
  ECID_ENCRYPTION_MISMATCH: 'ECID_ENCRYPTION_MISMATCH',
  GENERIC: 'GENERIC',
  HSENC_ENCRYPTION_MISMATCH: 'HSENC_ENCRYPTION_MISMATCH',
  HSENC_NOT_BASE_64: 'HSENC_NOT_BASE_64',
  INCORRECT_SURVEY_ID: 'INCORRECT_SURVEY_ID',
  INVALID_PARAMS: 'INVALID_PARAMS',
  INVALID_RATING: 'INVALID_RATING',
  IP_ADDRESS_IS_PROBABLY_A_BOT: 'IP_ADDRESS_IS_PROBABLY_A_BOT',
  NO_FORMS_FOR_PORTAL: 'NO_FORMS_FOR_PORTAL',
  NO_LIST_FOR_SURVEY: 'NO_LIST_FOR_SURVEY',
  NO_PORTAL_FOUND_FOR_PORTAL_ID: 'NO_PORTAL_FOUND_FOR_PORTAL_ID',
  NO_SURVEYS_FOR_PORTAL: 'NO_SURVEYS_FOR_PORTAL',
  SURVEY_NOT_FOUND: 'SURVEY_NOT_FOUND',
  NOT_AN_EMAIL_FORM: 'NOT_AN_EMAIL_FORM',
  OTHER: 'OTHER',
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  SUBMISSION_PERIOD_ENDED: 'SUBMISSION_PERIOD_ENDED',
  UNSUPPORTED_FEEDBACK_TYPE: 'UNSUPPORTED_FEEDBACK_TYPE'
};