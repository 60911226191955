import { DETRACTOR, PASSIVE, PROMOTER
// @ts-expect-error no types for this file
} from 'feedback-schema/constants/respondentTypes';
// @ts-expect-error no types
import DefaultIllustrationNames from 'feedback-schema/constants/illustrations/thankYou';
// @ts-expect-error it's just not typed
import { getRespondentType } from 'feedback-utils/respondentTypes';
export const getThankYouData = (surveyType, rating, config) => {
  const respondentType = getRespondentType(surveyType, rating);
  const configs = {
    [DETRACTOR]: {
      hasImage: config.hasThankYouDetractorImage,
      body: config.thankYouBodyDetractor || config.thankYouBody || config.thankYouMessageHtml,
      header: config.thankYouHeaderDetractor || config.thankYouHeader,
      imageUrl: config.thankYouDetractorImageUrl,
      imageAlt: config.thankYouDetractorImageAlt,
      illustration: DefaultIllustrationNames[respondentType]
    },
    [PASSIVE]: {
      hasImage: config.hasThankYouPassiveImage,
      body: config.thankYouBodyPassive || config.thankYouBody || config.thankYouMessageHtml,
      header: config.thankYouHeaderPassive || config.thankYouHeader,
      imageUrl: config.thankYouPassiveImageUrl,
      imageAlt: config.thankYouPassiveImageAlt,
      illustration: DefaultIllustrationNames[respondentType]
    },
    [PROMOTER]: {
      hasImage: config.hasThankYouPromoterImage,
      body: config.thankYouBodyPromoter || config.thankYouBody || config.thankYouMessageHtml,
      header: config.thankYouHeaderPromoter || config.thankYouHeader,
      imageUrl: config.thankYouPromoterImageUrl,
      imageAlt: config.thankYouPromoterImageAlt,
      illustration: DefaultIllustrationNames[respondentType]
    }
  };
  return configs[respondentType];
};