import enviro from 'enviro';
import * as ActionTypes from '../actions/ActionTypes';
import { ErrorTypes } from '../constants/ErrorConstants';
const initialState = {
  strings: {}
};
export default ((state = initialState, {
  error,
  payload,
  type
}) => {
  switch (type) {
    case ActionTypes.FETCH_SURVEY_CONFIG_SUCCESS:
      return Object.assign({}, state, payload, {
        isFetched: true,
        strings: payload.strings
      });
    case ActionTypes.FETCH_SURVEY_CONFIG_PENDING:
      return Object.assign({}, state, {
        isFetched: false
      });
    case ActionTypes.FETCH_SURVEY_CONFIG_ERROR:
      {
        let errorType;
        const json = error && error.responseText && JSON.parse(error.responseText);
        if (json) {
          errorType = json.error || ErrorTypes.OTHER;
        } else {
          errorType = ErrorTypes.GENERIC;
          // Timeout occurred, prompt refresh
          if (enviro.isQa()) {
            console.log(error);
          }
        }
        return Object.assign({}, state, {
          error: errorType,
          isFetched: true
        });
      }
    case ActionTypes.UPDATE_PREVIEW:
      {
        const {
          surveyConfigDelta,
          surveyConfigDelta: {
            strings
          }
        } = payload;
        return Object.assign({}, state, surveyConfigDelta, {
          strings: strings || state.strings
        });
      }
    default:
      return state;
  }
});