// @ts-expect-error not types
import { MOBILE } from 'feedback-schema/constants/devices';
// @ts-expect-error not types
import * as SurveyTypes from 'feedback-schema/constants/surveyTypes';
export function getRatingLabels(translatedStrings, surveyType, device) {
  const {
    cesStrings: {
      stronglyAgree,
      stronglyDisagree,
      mobileStronglyAgree,
      mobileStronglyDisagree
    },
    npsStrings: {
      extremelyLikely,
      notAtAllLikely,
      mobileExtremelyLikely,
      mobileNotAtAllLikely
    }
  } = translatedStrings;
  const isMobile = device === MOBILE;
  switch (surveyType) {
    case SurveyTypes.CES:
      {
        return {
          low: isMobile ? mobileStronglyDisagree : stronglyDisagree,
          high: isMobile ? mobileStronglyAgree : stronglyAgree
        };
      }
    case SurveyTypes.NPS:
      {
        return {
          low: isMobile ? mobileNotAtAllLikely : notAtAllLikely,
          high: isMobile ? mobileExtremelyLikely : extremelyLikely
        };
      }
    default:
      return {};
  }
}