// @ts-expect-error no type
import * as SurveyTypes from 'feedback-schema/constants/surveyTypes';
export const MOCK_CES_SUBMISSION_RESPONSE = {
  submissionId: 3
};
export const MOCK_STRINGS = {
  cesStrings: {
    mobileStronglyAgree: '<strong>7</strong> - Strongly agree',
    mobileStronglyDisagree: '<strong>1</strong> - Strongly disagree',
    question: 'Generic Company 5223415334 is doing a good job',
    stronglyAgree: 'Strongly agree',
    stronglyDisagree: 'Strongly disagree',
    toWhatExtent: 'To what extent do you agree with the following statement'
  },
  commonStrings: {
    submit: 'Submit',
    thisSurveyIsWeb: 'This survey was sent by some dood'
  },
  npsStrings: {
    extremelyLikely: 'Extremely likely',
    mobileExtremelyLikely: '<strong>10</strong> - Extremely likely',
    mobileNotAtAllLikely: '<strong>0</strong> - Not at all likely',
    notAtAllLikely: 'Not at all likely',
    question: 'Hello there, how are we doing?'
  }
};
const commonSurveyConfig = {
  enabled: true,
  followUpQuestionDetractor: "Sorry you're not pleased. What can we improve?",
  followUpQuestionPassive: 'What can we improve?',
  followUpQuestionPromoter: 'What are you happy about?',
  hasThankYouImage: true,
  hasThankYouDetractorImage: true,
  hasThankYouPassiveImage: true,
  hasThankYouPromoterImage: true,
  thankYouDetractorImageUrl: 'https://cdn2.hubspotqa.com/hubfs/99685920/crying.png',
  thankYouPassiveImageUrl: 'https://cdn2.hubspotqa.com/hubfs/99603609/joes2.gif',
  thankYouPromoterImageUrl: 'https://cdn2.hubspotqa.com/hubfs/99685920/balloons.png',
  strings: MOCK_STRINGS,
  themeColor: '#7f7fff'
};
export const MockSurveyConfig = {
  [SurveyTypes.NPS]: Object.assign({}, commonSurveyConfig, {
    brandName: 'Outbound Inc',
    ratingQuestion: 'How likely are you to recommend Outbound Inc to a friend or colleague?',
    thankYouMessageHtml: '<div><div class="thank-you-header">Thank you.</div><div class="thank-you-body">Your feedback means the world to us.</div></div>',
    thankYouHeaderDetractor: 'Thank you so much',
    thankYouHeaderPassive: 'Thank you so much',
    thankYouHeaderPromoter: 'Thank you so much',
    thankYouBodyDetractor: 'Your feedback means the world to us',
    thankYouBodyPassive: 'Your feedback means the world to us',
    thankYouBodyPromoter: 'Your feedback means the world to us'
  }),
  [SurveyTypes.CES]: Object.assign({}, commonSurveyConfig, {
    brandName: 'Outbound Inc',
    ratingQuestion: 'How did we do in helping you solve your case?',
    thankYouBody: 'Your feedback means the world to us.',
    thankYouHeader: 'Thank you.'
  }),
  [SurveyTypes.CSAT]: Object.assign({}, commonSurveyConfig, {
    introduction: "You recently bought something from our online shop. We'd love to know what you thought.",
    ratingQuestion: 'How happy were you with the overall buying experience?',
    thankYouHeaderDetractor: 'Thank you so much',
    thankYouHeaderPassive: 'Thank you so much',
    thankYouHeaderPromoter: 'Thank you so much',
    thankYouBodyDetractor: 'We read every feedback. We will try our best to get better',
    thankYouBodyPassive: 'We will try our best to get better',
    thankYouBodyPromoter: 'Your feedback means the world to us'
  })
};