'use es6';

import { DETRACTOR, PASSIVE, PROMOTER } from './respondentTypes';
import { CES, CSAT, NPS } from './surveyTypes';
const respondentTypeLimits = {
  [CES]: {
    [DETRACTOR]: 3,
    [PASSIVE]: 5,
    [PROMOTER]: 7
  },
  [NPS]: {
    [DETRACTOR]: 6,
    [PASSIVE]: 8,
    [PROMOTER]: 10
  },
  [CSAT]: {
    [DETRACTOR]: 0,
    [PASSIVE]: 1,
    [PROMOTER]: 2
  }
};
export default respondentTypeLimits;