import * as ActionTypes from '../actions/ActionTypes';
const initialState = {};
export default ((state = initialState, {
  error,
  payload,
  type
}) => {
  switch (type) {
    case ActionTypes.SET_FEEDBACK_RATING:
      return Object.assign({}, state, {
        rating: payload.rating
      });
    case ActionTypes.SET_FEEDBACK_FOLLOW_UP:
      return Object.assign({}, state, {
        followUp: payload.followUp
      });
    case ActionTypes.INITIAL_SUBMISSION_PENDING:
      return state;
    case ActionTypes.FOUND_SUBMISSION_ID_COOKIE:
    case ActionTypes.INITIAL_SUBMISSION_SUCCESS:
      return Object.assign({}, state, {
        submissionId: payload.submissionId
      });
    case ActionTypes.INITIAL_SUBMISSION_ERROR:
      return Object.assign({}, state, {
        error
      });
    case ActionTypes.SUBMIT_FOLLOW_UP_PENDING:
      return Object.assign({}, state, {
        isSubmittingFollowUp: true
      });
    case ActionTypes.SUBMIT_FOLLOW_UP_SUCCESS:
      return Object.assign({}, state, {
        followUpIsSubmitted: true,
        isSubmittingFollowUp: false
      });
    case ActionTypes.SUBMIT_FOLLOW_UP_ERROR:
      return Object.assign({}, state, {
        error,
        isSubmittingFollowUp: false
      });
    default:
      return state;
  }
});