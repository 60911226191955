import { useEffect, useState } from 'react';
import { getScreenSize } from 'forms-utils-lib/utils/getScreenSize';
import { SIZES, INITIAL_BREAKPOINTS } from 'forms-utils-lib/constants/screen';
export function useWindowSize(breakpoints = INITIAL_BREAKPOINTS) {
  const [currentSize, setCurrentSize] = useState(SIZES.DESKTOP_LG);
  useEffect(() => {
    const handleResize = e => {
      e.preventDefault();
      const size = getScreenSize(breakpoints)(window.innerWidth);
      setCurrentSize(size);
    };
    window.addEventListener('resize', handleResize);
  });
  useEffect(() => {
    setCurrentSize(getScreenSize(breakpoints)(window.innerWidth));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return currentSize;
}