// @ts-expect-error types missing

// @ts-expect-error types missing
import { fetchEarlyRequest } from 'feedback-utils/earlyRequest';
import * as EmailVerifyApi from '../api/EmailVerify';
import { COOKIE_SUBMISSION_ID } from '../constants/common';
import { composeCookieName, getCookie } from '../utils/CookieUtils';
import * as ActionTypes from './ActionTypes';
import { foundSubmissionIdCookie, sendInitialSubmission } from './InitialSubmissionActions';
const updateSurveyConfig = (surveyType, payload) => ({
  type: ActionTypes.FETCH_SURVEY_CONFIG_SUCCESS,
  payload: Object.assign({}, payload, {
    surveyType
  })
});
const surveyConfigRequesting = {
  type: ActionTypes.FETCH_SURVEY_CONFIG_PENDING
};
const surveyConfigRequestError = error => ({
  type: ActionTypes.FETCH_SURVEY_CONFIG_ERROR,
  error
});
const surveyConfigReceived = (surveyType, payload, params) => dispatch => {
  const {
    contactId,
    hasReenrolledSinceLastSubmission,
    portalId
  } = payload;
  dispatch(updateSurveyConfig(surveyType, payload));
  const submissionId = getCookie(composeCookieName(contactId, COOKIE_SUBMISSION_ID, portalId, params.surveyId, surveyType, params.ticketId || ''));
  if (hasReenrolledSinceLastSubmission || !submissionId) {
    dispatch(sendInitialSubmission(surveyType, contactId, params));
  } else {
    dispatch(foundSubmissionIdCookie(submissionId));
  }
};
export const fetchSurveyConfig = (surveyType, params) => dispatch => {
  dispatch(surveyConfigRequesting);
  fetchEarlyRequest('email-verify', error => error && error.response ?
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({
    responseText: error.response
  }) : EmailVerifyApi.fetchConfig(surveyType, params)).then(response => dispatch(surveyConfigReceived(surveyType, response, params)), error => dispatch(surveyConfigRequestError(error))).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
};
export const updatePreview = surveyConfigDelta => ({
  type: ActionTypes.UPDATE_PREVIEW,
  payload: {
    surveyConfigDelta
  }
});