'use es6';

import { DESKTOP, TABLET, MOBILE, MOBILE_LANDSCAPE } from 'feedback-schema/constants/devices';
export const getIsNpsRatings = (name = '') => name === 'nps-rating';
export const getIsDeviceMobileOrTablet = device => device === MOBILE || device === TABLET;
export const getIsTabletOrMobileSize = deviceWidth => [MOBILE_LANDSCAPE, MOBILE, TABLET].includes(deviceWidth);
export const getMaxButtonsPerRow = ({
  buttonsCount,
  device,
  isNpsRatings
}) => isNpsRatings || device === DESKTOP || device === TABLET && buttonsCount <= 7 ? buttonsCount : Math.ceil(buttonsCount / 2);