export const Selectors = {
  HOMEPAGE: 'homepage',
  FOOTER: 'footer',
  THANK_YOU: {
    CONTAINER: 'thank-you',
    HEADER: 'thank-you-header',
    BODY: 'thank-you-header-body'
  },
  SURVEY_QUESTION: 'question-text',
  FOLLOW_UP: {
    TEXT: 'follow-up-question-text',
    INPUT: 'follow-up-input'
  },
  SUBMIT_BUTTON: 'submit-button',
  ERROR: 'error-container'
};
export default Selectors;