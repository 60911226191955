import { useEffect, useState } from 'react';

// @ts-expect-error no types
import getDeviceType from 'feedback-utils/getDeviceType';
export const useDeviceSizeChange = () => {
  const [device, setDevice] = useState(getDeviceType(window.innerWidth));
  const handleWindowSizeChange = () => setDevice(getDeviceType(window.innerWidth));
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);
  return {
    device
  };
};