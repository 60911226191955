import Raven from 'raven-js';
export const checkForErrors = (query, surveyType) => {
  const buildError = message => Raven.captureMessage(message, {
    extra: {
      query,
      location: window.location.href
    }
  });
  if (query.emailSentAt === null || !query.emailSentAt) {
    buildError('EMAIL_SENT_AT_DOESNT_EXIST');
  }
  if (query.portalId === null || !query.portalId) {
    buildError('PORTAL_ID_DOESNT_EXIST');
  }
  if (query.surveyId === null || !query.surveyId) {
    buildError('SURVEY_ID_DOESNT_EXIST');
  }
  if (query.surveyId === null || !query.surveyId) {
    buildError('SURVEY_ID_DOESNT_EXIST');
  }
  if (query.rating === null || !query.rating) {
    buildError('THERE_IS_NO_RATING_PRESENT');
  }
  if (query.rating === null || !query.rating) {
    buildError('THERE_IS_NO_RATING_PRESENT');
  }
  if (surveyType === 'CES') {
    if (query['objectReference.objectReferenceType'] !== 'TICKET') {
      if (query.rating === null || !query.rating) {
        buildError('OBJECT_REFERENCE_TYPE_IS_BAD');
      }
    }
    if (query['objectReference.objectReferenceId'] === null || !query['objectReference.objectReferenceId']) {
      if (query.rating === null || !query.rating) {
        buildError('OBJECT_REFERENCE_ID_IS_BAD');
      }
    }
  }
};