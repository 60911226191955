import { isWholeNumber } from './isWholeNumber';
import { canIdentifyContact } from './canIdentifyContact';
export const areParamsValid = queryParams => {
  if (queryParams.isDebug || queryParams.isPreview) {
    return true;
  }
  const ts = parseInt(queryParams.ts, 10);
  const areTimesValid = isWholeNumber(ts) && Date.now() > ts;
  const hasContactAndSurvey = Boolean(queryParams.test || canIdentifyContact(queryParams) && queryParams.surveyId);
  if (hasContactAndSurvey && isWholeNumber(parseInt(queryParams.rating, 10)) && areTimesValid) {
    return true;
  }
  return false;
};