'use es6';

const handleSplit = host => {
  const origin = host.split('.')[0];
  if (/-/.test(origin)) {
    return origin.split('-')[1];
  }
  return '';
};

/*
  @name getHubletFromUrl
  @returns String
  @params location
    this will usually be window.location passed in via argument because of weird iframe stuff.
  @usage
    const hublet = getHubletFromUrl(window.location); // -test2, '', '-eu1' etc
*/
const getHubletFromUrl = (location = '') => {
  if (location.host) {
    return handleSplit(location.host);
  }
  if (location) {
    return handleSplit(location);
  }
  return '';
};
export default getHubletFromUrl;