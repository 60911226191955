import { SIZES } from 'forms-utils-lib/constants/screen';
import { memoize } from './memoize';
export const getScreenSize = breakpoints => memoize(width => {
  if (width <= breakpoints.MOBILE) {
    return SIZES.MOBILE;
  }
  if (width > breakpoints.MOBILE && width <= breakpoints.MOBILE_LANDSCAPE) {
    return SIZES.MOBILE_LANDSCAPE;
  }
  if (width > breakpoints.MOBILE_LANDSCAPE && width <= breakpoints.TABLET) {
    return SIZES.TABLET;
  }
  if (width > breakpoints.TABLET && width <= breakpoints.LAPTOP) {
    return SIZES.LAPTOP;
  }
  if (width > breakpoints.LAPTOP && width <= breakpoints.DESKTOP) {
    return SIZES.DESKTOP;
  }
  if (width > breakpoints.DESKTOP && width <= breakpoints.DESKTOP_LG) {
    return SIZES.DESKTOP_LG;
  }
  if (width > breakpoints.DESKTOP_LG) {
    return SIZES.DESKTOP_XL;
  }
  return SIZES.DESKTOP_LG;
});