import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { hubletApi } from 'hub-http/middlewares/core';
// @ts-expect-error no types
import * as SurveyTypes from 'feedback-schema/constants/surveyTypes';
import { MockSurveyConfig } from '../constants/MockData';
import { checkForErrors } from '../utils/checkForErrors';
import { captureException } from 'raven-js';
const EMAIL_VERIFY_API = 'feedback/public/v1/email-verify';
const createEmailVerifyRequest = (surveyType, params) => Object.assign({
  emailSentAt: params.ts,
  portalId: params.portalId,
  rating: params.rating,
  surveyId: params.surveyId,
  surveyType
}, surveyType === SurveyTypes.CES && !params.test && params.ticketId ? {
  'objectReference.objectReferenceId': params.ticketId,
  'objectReference.objectReferenceType': 'TICKET'
} : {}, {
  ecid: params.ecid
}, params.test ? {
  preview: true
} : {
  preview: false
}, {
  _hsenc: params._hsenc
});
export const fetchConfig = (surveyType, params) => {
  const {
    isPreview,
    isDebug
  } = params;
  if (isPreview || isDebug) {
    return new Promise(res => {
      res(isDebug ? MockSurveyConfig[surveyType] : {});
    });
  }
  const query = createEmailVerifyRequest(surveyType, params);
  checkForErrors(query, surveyType);
  const httpQuery = createEmailVerifyRequest(surveyType, params);
  if (httpQuery.emailSentAt === null) {
    captureException(Error('Email sent at is null'), {
      extra: Object.assign({
        location: window.location.href
      }, httpQuery)
    });
  }
  return noAuthApiClient.get(EMAIL_VERIFY_API, {
    api: hubletApi('feedback', 'hubapi'),
    query: httpQuery,
    withCredentials: false
  });
};