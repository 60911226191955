'use es6';

export const BRAND_NAME_PLACEHOLDER = 'brand_name';
export const COMPANY_DOMAIN_PLACEHOLDER = 'company_domain';
export const TICKET_ID_PLACEHOLDER = 'ticket.hs_ticket_id';
export const TICKET_SUBJECT_PLACEHOLDER = 'ticket.subject';
export const UNSUBSCRIBE_LINK_PLACEHOLDER = 'unsubscribe_link';
export const EXTRACT_FALLBACK_PROPERTIES_REGEX = /\{\{\s(?:personalization_token\(")?(\w*)\.(\w*(?:\.\w*)?)(?:",\s")?([^{}")]*)(?:"\))?\s\}\}/gi;
export const EXTRACT_FALLBACK_ONLY_REGEX = /\{\{\s(?:personalization_token\(")?(?:\w*)\.(?:\w*(\.\w*)?)(?:",\s")?([^{}"]*)(?:"\))?\s\}\}/gi;
export const EXTRACT_PROPERTIES_REGEX = /\{\{\s(\w*)\.(\w*(\.\w*)?)\s\}\}/gi;
export const EXTRACT_CONTACT_TOKENS_REGEX = /(?:\S+):\s(?:\S+)/gm;
export const HAS_FALLBACK_REGEX = /\{\{\spersonalization_token\(.*\)\s\}\}/;
export const FALLBACK_TAG_REGEX = /\{\{\s(\w)*\("(\w)*\.(\w)*",\s"(\w)*"\)\s\}\}/gi;
export const TAG_REGEX = /\{\{\s(\w)*\.(\w)*\s\}\}/gi;
export const BREAK_REGEX = /<br ?\/?>/gm;
export const HAS_HTML_REGEX = /(<\w*)((\s\/>)|(.*<\/\w*>))/gm;
export const EXTRACT_SUB_PROPERTY = /(\w*)\.(\w*)/gi;
export const REMOVE_HTML_TAGS_REGEX = /(<([^>]+)>)/gi;