/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import quickFetch from 'quick-fetch';
export const fetchEarlyRequest = (requestName, makeRequest = () => new Promise(resolve => resolve())) => {
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(requestName);
      });
      earlyRequest.onError(error => {
        makeRequest(error).then(resolve, reject);
        quickFetch.removeEarlyRequest(requestName);
      });
    });
  }
  return makeRequest();
};