module.exports = {
  "/": {
    "success": {
      "default": [
        "ERROR_MESSAGE_LOADED"
      ]
    }
  },
  "/ces": {
    "success": {
      "error message loaded": [
        "ERROR_MESSAGE_LOADED"
      ],
      "survey successfully loaded": [
        "CES_RATING_QUESTION_LOADED",
        "FOLLOW-UP_INPUT_LOADED",
        "SUBMIT_BUTTON_LOADED"
      ],
      "thank you successfully loaded": [
        "THANK_YOU_INPUT_LOADED"
      ]
    }
  },
  "/csat": {
    "success": {
      "error message loaded": [
        "ERROR_MESSAGE_LOADED"
      ],
      "survey successfully loaded": [
        "CSAT_RATING_QUESTION_LOADED",
        "FOLLOW-UP_INPUT_LOADED",
        "SUBMIT_BUTTON_LOADED"
      ],
      "thank you successfully loaded": [
        "THANK_YOU_INPUT_LOADED"
      ]
    }
  },
  "/nps": {
    "success": {
      "error message loaded": [
        "ERROR_MESSAGE_LOADED"
      ],
      "survey successfully loaded": [
        "NPS_RATING_QUESTION_LOADED",
        "FOLLOW-UP_INPUT_LOADED",
        "SUBMIT_BUTTON_LOADED"
      ],
      "thank you successfully loaded": [
        "THANK_YOU_INPUT_LOADED"
      ]
    }
  }
};