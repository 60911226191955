import { parse } from 'hub-http/helpers/params';
export const getInitialQueryParams = params => {
  const paramsObject = parse(params);
  return {
    ts: paramsObject.ts || '',
    ecid: paramsObject.ecid || '',
    test: paramsObject.test || '',
    _hsenc: paramsObject._hsenc || '',
    rating: paramsObject.rating || '',
    portalId: paramsObject.portalId || '',
    surveyId: paramsObject.surveyId || '',
    ticketId: paramsObject.ticketId || '',
    surfaceError: paramsObject.surfaceError || '',
    isDebug: paramsObject.isDebug === 'true',
    isPreview: paramsObject.isPreview === 'true'
  };
};