import * as FeedbackSubmissionApi from '../api/FeedbackSubmission';
import { COOKIE_SUBMISSION_ID } from '../constants/common';
import { ErrorTypes } from '../constants/ErrorConstants';
import { composeCookieName, setCookie } from '../utils/CookieUtils';
import * as ActionTypes from './ActionTypes';
const initialSubmissionSuccess = payload => ({
  type: ActionTypes.INITIAL_SUBMISSION_SUCCESS,
  payload
});
const initialSubmissionPending = {
  type: ActionTypes.INITIAL_SUBMISSION_PENDING
};
const initialSubmissionError = {
  error: ErrorTypes.OTHER,
  type: ActionTypes.INITIAL_SUBMISSION_ERROR
};
export const foundSubmissionIdCookie = submissionId => ({
  type: ActionTypes.FOUND_SUBMISSION_ID_COOKIE,
  payload: {
    submissionId
  }
});
export const sendInitialSubmission = (surveyType, contactId, params) => dispatch => {
  dispatch(initialSubmissionPending);
  FeedbackSubmissionApi.submit(surveyType, contactId, params).then(response => {
    const {
      portalId,
      surveyId,
      ticketId
    } = params;
    setCookie(composeCookieName(contactId, COOKIE_SUBMISSION_ID, portalId, surveyId, surveyType, ticketId || ''), response.submissionId);
    dispatch(initialSubmissionSuccess(response));
  }, () => dispatch(initialSubmissionError)).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
};