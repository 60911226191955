'use es6';

import * as RespondentTypes from 'feedback-schema/constants/respondentTypes';
import RespondentTypeLimits from 'feedback-schema/constants/respondentTypeLimits';
import { isCustomSurvey } from './surveyTypeUtils';
export const getRespondentType = (surveyType, selectedRating) => {
  if (isCustomSurvey(surveyType)) {
    return '';
  }
  if (selectedRating <= RespondentTypeLimits[surveyType][RespondentTypes.DETRACTOR]) {
    return RespondentTypes.DETRACTOR;
  } else if (selectedRating <= RespondentTypeLimits[surveyType][RespondentTypes.PASSIVE]) {
    return RespondentTypes.PASSIVE;
  }
  return RespondentTypes.PROMOTER;
};