import { ErrorTypes, ErrorCategories, ErrorIllustrations
// @ts-expect-error no idea why
} from '../constants/ErrorConstants';
export function getErrorStateConfig(errorType) {
  switch (errorType) {
    case ErrorTypes.CONTACT_HAS_RECENTLY_SUBMITTED:
      return {
        illustration: ErrorIllustrations[ErrorCategories.ALREADY_SUBMITTED],
        langKey: `feedbackPublicUi.error.${ErrorCategories.ALREADY_SUBMITTED}`
      };
    case ErrorTypes.SUBMISSION_PERIOD_ENDED:
      return {
        illustration: ErrorIllustrations[ErrorCategories.TOO_LATE],
        langKey: `feedbackPublicUi.error.${ErrorCategories.TOO_LATE}`
      };
    case ErrorTypes.ASSOCIATED_TICKET_NOT_FOUND:
    case ErrorTypes.CONTACT_NOT_IN_LIST:
    case ErrorTypes.CONTACT_NOT_IN_PORTAL:
    case ErrorTypes.ECID_ENCRYPTION_MISMATCH:
    case ErrorTypes.HSENC_ENCRYPTION_MISMATCH:
    case ErrorTypes.HSENC_NOT_BASE_64:
    case ErrorTypes.INCORRECT_SURVEY_ID:
    case ErrorTypes.INVALID_PARAMS:
    case ErrorTypes.INVALID_RATING:
    case ErrorTypes.IP_ADDRESS_IS_PROBABLY_A_BOT:
    case ErrorTypes.NO_FORMS_FOR_PORTAL:
    case ErrorTypes.NO_LIST_FOR_SURVEY:
    case ErrorTypes.NO_PORTAL_FOUND_FOR_PORTAL_ID:
    case ErrorTypes.SURVEY_NOT_FOUND:
    case ErrorTypes.NO_SURVEYS_FOR_PORTAL:
    case ErrorTypes.NOT_AN_EMAIL_FORM:
    case ErrorTypes.OTHER:
    case ErrorTypes.UNSUPPORTED_FEEDBACK_TYPE:
      return {
        illustration: ErrorIllustrations[ErrorCategories.UNSUBMITTABLE],
        langKey: `feedbackPublicUi.error.${ErrorCategories.UNSUBMITTABLE}`
      };
    case ErrorTypes.RATE_LIMIT_EXCEEDED:
      return {
        illustration: ErrorIllustrations[ErrorCategories.RATE_LIMIT_EXCEEDED],
        langKey: `feedbackPublicUi.error.${ErrorCategories.RATE_LIMIT_EXCEEDED}`
      };
    case ErrorTypes.GENERIC:
    default:
      return {
        illustration: ErrorIllustrations[ErrorCategories.UNKNOWN],
        langKey: `feedbackPublicUi.error.${ErrorCategories.UNKNOWN}`
      };
  }
}