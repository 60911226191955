import * as FeedbackSubmissionApi from '../api/FeedbackSubmission';
import { COOKIE_SUBMITTED_FOLLOW_UP } from '../constants/common';
import { ErrorTypes } from '../constants/ErrorConstants';
import { composeCookieName, setCookie } from '../utils/CookieUtils';
import { shouldSubmit } from '../utils/shouldSubmit';
import * as ActionTypes from './ActionTypes';
export function setFollowUp(followUp) {
  return {
    type: ActionTypes.SET_FEEDBACK_FOLLOW_UP,
    payload: {
      followUp
    }
  };
}
export const setRating = rating => ({
  type: ActionTypes.SET_FEEDBACK_RATING,
  payload: {
    rating
  }
});
const sendFollowUpSuccess = {
  type: ActionTypes.SUBMIT_FOLLOW_UP_SUCCESS
};
const sendFollowUpPending = {
  type: ActionTypes.SUBMIT_FOLLOW_UP_PENDING
};
const sendFollowUpError = {
  error: ErrorTypes.OTHER,
  type: ActionTypes.SUBMIT_FOLLOW_UP_ERROR
};
export function submitFollowUp(params, surveyConfig, feedbackResponse, surveyType) {
  return dispatch => {
    dispatch(sendFollowUpPending);
    FeedbackSubmissionApi.submitFollowUp(params, surveyConfig, feedbackResponse, surveyType).then(() => {
      const {
        portalId,
        surveyId,
        ticketId
      } = params;
      dispatch(sendFollowUpSuccess);
      if (shouldSubmit(params)) {
        setCookie(composeCookieName(surveyConfig.contactId, COOKIE_SUBMITTED_FOLLOW_UP, portalId, surveyId, surveyType, ticketId || ''), true);
      }
    }, () => dispatch(sendFollowUpError)).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return undefined;
  };
}