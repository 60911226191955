export const FETCH_SURVEY_CONFIG_PENDING = 'FETCH_SURVEY_CONFIG_PENDING';
export const FETCH_SURVEY_CONFIG_SUCCESS = 'FETCH_SURVEY_CONFIG_SUCCESS';
export const FETCH_SURVEY_CONFIG_ERROR = 'FETCH_SURVEY_CONFIG_ERROR';
export const FOUND_SUBMISSION_ID_COOKIE = 'FOUND_SUBMISSION_ID_COOKIE';
export const INITIAL_SUBMISSION_SUCCESS = 'INITIAL_SUBMISSION_SUCCESS';
export const INITIAL_SUBMISSION_PENDING = 'INITIAL_SUBMISSION_PENDING';
export const INITIAL_SUBMISSION_ERROR = 'INITIAL_SUBMISSION_ERROR';
export const SET_FEEDBACK_FOLLOW_UP = 'SET_FEEDBACK_FOLLOW_UP';
export const SET_FEEDBACK_RATING = 'SET_FEEDBACK_RATING';
export const SUBMIT_FOLLOW_UP_PENDING = 'SUBMIT_FOLLOW_UP_PENDING';
export const SUBMIT_FOLLOW_UP_SUCCESS = 'SUBMIT_FOLLOW_UP_SUCCESS';
export const SUBMIT_FOLLOW_UP_ERROR = 'SUBMIT_FOLLOW_UP_ERROR';
export const UPDATE_PREVIEW = 'UPDATE_PREVIEW';