import { DETRACTOR, PASSIVE, PROMOTER
// @ts-expect-error no types for this file
} from 'feedback-schema/constants/respondentTypes';

// @ts-expect-error it's just not typed
import { getRespondentType } from 'feedback-utils/respondentTypes';
export const getFollowUpQuestion = (surveyType, rating, config) => {
  const respondentType = getRespondentType(surveyType, rating);
  switch (respondentType) {
    case DETRACTOR:
      return config.followUpQuestionDetractor;
    case PASSIVE:
      return config.followUpQuestionPassive;
    case PROMOTER:
      return config.followUpQuestionPromoter;
    default:
      return null;
  }
};