// @ts-expect-error no types
import { getCookie as hubHttpGetCookie } from 'hub-http/helpers/cookies';
const ONE_WEEK_MILLIS = 1000 * 60 * 60 * 24 * 7;
const mayGetTicketId = maybeTicketId => maybeTicketId ? `_${maybeTicketId}` : '';
export const composeCookieName = (contactId, id, portalId, surveyId, surveyType, ticketId) => `${id}_${portalId}_${contactId}_${surveyType}_${surveyId}${mayGetTicketId(ticketId)}`.trim();
export const getCookie = name => hubHttpGetCookie(name, document.cookie);
export const setCookie = (name, value) => {
  const currentTime = new Date();
  const expiryTime = new Date(currentTime.getTime() + ONE_WEEK_MILLIS);
  document.cookie = `${name}=${value};expires=${expiryTime.toUTCString()};path=/`;
};