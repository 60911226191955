export const INITIAL_BREAKPOINTS = {
  MOBILE: 325,
  MOBILE_LANDSCAPE: 640,
  TABLET: 768,
  LAPTOP: 1024,
  DESKTOP: 1280,
  DESKTOP_LG: 1536
};
export const SIZES = {
  MOBILE: 'MOBILE',
  MOBILE_LANDSCAPE: 'MOBILE_LANDSCAPE',
  TABLET: 'TABLET',
  LAPTOP: 'LAPTOP',
  DESKTOP: 'DESKTOP',
  DESKTOP_LG: 'DESKTOP_LG',
  DESKTOP_XL: 'DESKTOP_XL'
};