'use es6';

export const OWNER_PROPERTIES = {
  fullname: {
    label: 'Full Name',
    name: 'fullname'
  },
  firstname: {
    label: 'First Name',
    name: 'firstname'
  },
  lastname: {
    label: 'Last Name',
    name: 'lastname'
  },
  email: {
    label: 'Email',
    name: 'email'
  },
  signature: {
    label: 'Signature',
    name: 'signature'
  },
  meetings_link: {
    label: 'Meeting link',
    name: 'meetings_link'
  }
};